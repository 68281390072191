exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-about-lupus-nephritis-index-jsx": () => import("./../../../src/pages/about-lupus-nephritis/index.jsx" /* webpackChunkName: "component---src-pages-about-lupus-nephritis-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-us-confirmed-index-jsx": () => import("./../../../src/pages/contact-us-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-confirmed-index-jsx" */),
  "component---src-pages-diagnosis-and-treatment-index-jsx": () => import("./../../../src/pages/diagnosis-and-treatment/index.jsx" /* webpackChunkName: "component---src-pages-diagnosis-and-treatment-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-managing-lupus-nephritis-index-jsx": () => import("./../../../src/pages/managing-lupus-nephritis/index.jsx" /* webpackChunkName: "component---src-pages-managing-lupus-nephritis-index-jsx" */),
  "component---src-pages-personal-stories-index-jsx": () => import("./../../../src/pages/personal-stories/index.jsx" /* webpackChunkName: "component---src-pages-personal-stories-index-jsx" */),
  "component---src-pages-register-index-jsx": () => import("./../../../src/pages/register/index.jsx" /* webpackChunkName: "component---src-pages-register-index-jsx" */),
  "component---src-pages-registration-confirmed-index-jsx": () => import("./../../../src/pages/registration-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-registration-confirmed-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-resubscribe-confirmed-index-jsx": () => import("./../../../src/pages/resubscribe-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-resubscribe-confirmed-index-jsx" */),
  "component---src-pages-sitemap-index-jsx": () => import("./../../../src/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-sitemap-index-jsx" */),
  "component---src-pages-unsubscribe-confirmed-index-jsx": () => import("./../../../src/pages/unsubscribe-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-confirmed-index-jsx" */),
  "component---src-pages-unsubscribe-index-jsx": () => import("./../../../src/pages/unsubscribe/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-jsx" */)
}

